<template>
    <header class="z-40 bg-primary" :class="{ dark: store.semidark && store.menu === 'horizontal' }">
        <div class="shadow-sm">
            <div class="relative flex w-full items-center bg-white px-5 py-2.5 dark:bg-[#0e1726]">
                <div class="horizontal-logo flex items-center justify-between lg:hidden ltr:mr-2 rtl:ml-2">
                    <NuxtLink to="/" class="main-logo flex shrink-0 items-center">
                        <img class="inline w-8 ltr:-ml-1 rtl:-mr-1" src="/assets/images/teamss_logo.png" alt="" />
                        <span
                            class="hidden align-middle text-2xl font-semibold transition-all duration-300 dark:text-white-light md:inline ltr:ml-1.5 rtl:mr-1.5"
                            >TEAMSS</span
                        >
                    </NuxtLink>
                </div>
                <div class="hidden sm:block ltr:mr-2 rtl:ml-2"><NuxtLink to="/" > <img class="inline w-36 ltr:-ml-1 rtl:-mr-1" src="/assets/images/teamss_logo.png" alt="" /></NuxtLink></div>
                <div class="flex justify-end space-x-1.5 dark:text-[#d0d2d6] sm:flex-1 lg:space-x-3">
                    <div>
                        <NuxtLink to="/" href="javascript:;" class="flex items-center p-2 hover:text-primary text-lg">Home</NuxtLink>
                    </div>

                    <div>
                        <NuxtLink to="/b2c" href="javascript:;" class="flex items-center p-2 hover:text-primary text-lg">Property</NuxtLink>
                    </div>
                    <div>
                        <NuxtLink to="/#modules" href="javascript:;" class="flex items-center p-2 hover:text-primary text-lg" >Features</NuxtLink>
                    </div>
                    <div>
                        <NuxtLink to="/b2c/marketing" href="javascript:;" class="flex items-center p-2 hover:text-primary text-lg" >Marketing</NuxtLink>
                    </div>
                    <!-- <div>
                        <NuxtLink to="/#modules" href="javascript:;" class="flex items-center p-2 hover:text-primary text-lg" >Modules</NuxtLink>
                    </div> -->
                    <div>
                        <NuxtLink  to="/#pricing" href="javascript:;" class="flex items-center p-2 hover:text-primary text-lg">Pricing</NuxtLink>
                    </div>
                    <div>
                        <NuxtLink to="/#faq"  href="javascript:;" class="flex items-center p-2 hover:text-primary text-lg">FAQ</NuxtLink>
                    </div>
                    <div class="relative ml-4" v-if="authStore.partnerName">
                        <button @click="toggleProfileMenu" class="flex items-center p-2   text-lg">
                            <!-- <img class="w-8 h-8 rounded-full" :src="authStore.userProfilePic" alt="Profile" /> -->
                            <span class="hidden lg:inline">Hi, {{ authStore.partnerName || 'Profile' }}</span>
                            <svg class="w-5 h-5" xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round">
                                <path d="M19 9l-7 7-7-7"></path>
                            </svg>
                        </button>
                        <!-- Dropdown Menu -->
                        <div v-if="profileMenuOpen" class="absolute right-0 mt-2 w-48 bg-white dark:bg-[#0e1726] border border-gray-300 dark:border-gray-600 rounded-lg shadow-lg">
                            <NuxtLink to="/profile" class="block px-4 py-2 text-sm text-gray-700 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700">Profile</NuxtLink>
                            <NuxtLink to="/settings" class="block px-4 py-2 text-sm text-gray-700 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700">Settings</NuxtLink>
                            <button @click="handleLogout" class="block px-4 py-2 text-sm text-gray-700 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700 w-full text-left">Logout</button>
                        </div>
                    </div>

                </div>
                <div class="border-r border-gray-300 h-6 pr-4"></div>
                <div class="flex justify-right items-center pl-4">
                    <div class="ml-auto"> <!-- Add ml-auto class to align to the right -->
                        <div v-if="authStore.partnerId">
                        <button class="flex btn bg-primary text-white rounded-full px-8 py-2 items-center p-2 hover:text-white text-sm"  @click="handleDashboardClick">Dashboard </button>
                        </div>
                       <div v-else-if="!authStore.partnerName">
                        <button class="flex btn bg-primary text-white rounded-full px-8 py-2 items-center p-2 hover:text-white text-sm" @click="openModal">LOGIN</button>
                        </div>                        <!-- <a href="javascript:;" ></a> -->
                    </div>
                </div>
            </div>
        </div>
        <LoginModal :isOpen="isModalOpen" @close="closeModal" />
    </header>
</template>

<script lang="ts" setup>
    import { ref, onMounted, computed, reactive, watch } from 'vue';
    import appSetting from '@/app-setting';
    import { useAuthStore } from '@/stores/authStore';
    import { useRoute } from 'vue-router';
    import { useAppStore } from '@/stores/index';
    import LoginModal from '@/components/b2c/LoginModal.vue';
    const store = useAppStore();
    const authStore = useAuthStore();
    const route = useRoute();
    const search = ref(false);
    const { setLocale } = useI18n();

    const isModalOpen = ref(false);
    const profileMenuOpen = ref(false);

    const openModal = () => {
    isModalOpen.value = true;
    };

    const closeModal = () => {
    isModalOpen.value = false;
    };
    const toggleProfileMenu = () => {
        profileMenuOpen.value = !profileMenuOpen.value;
    };

    const handleLogout = async () => {
        // Your logout logic here
        // @click="() => { authStore.signOut(); close(); }"
        await authStore.signOut();
        profileMenuOpen.value = false; // Close the menu on logout
    };
    // multi language
    const changeLanguage = (item: any) => {
        appSetting.toggleLanguage(item, setLocale);
    };
    const currentFlag = computed(() => {
        return `/assets/images/flags/${store.locale?.toUpperCase()}.svg`;
    });

    const notifications = ref([
        {
            id: 1,
            profile: 'user-profile.jpeg',
            message: '<strong class="text-sm mr-1">John Doe</strong>invite you to <strong>Prototyping</strong>',
            time: '45 min ago',
        },
        {
            id: 2,
            profile: 'profile-34.jpeg',
            message: '<strong class="text-sm mr-1">Adam Nolan</strong>mentioned you to <strong>UX Basics</strong>',
            time: '9h Ago',
        },
        {
            id: 3,
            profile: 'profile-16.jpeg',
            message: '<strong class="text-sm mr-1">Anna Morgan</strong>Upload a file',
            time: '9h Ago',
        },
    ]);

    const messages = ref([
        {
            id: 1,
            image: '<span class="grid place-content-center w-9 h-9 rounded-full bg-success-light dark:bg-success text-success dark:text-success-light"><svg xmlns="http://www.w3.org/2000/svg" class="w-5 h-5" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"><path d="M12 22s8-4 8-10V5l-8-3-8 3v7c0 6 8 10 8 10z"></path></svg></span>',
            title: 'Congratulations!',
            message: 'Your OS has been updated.',
            time: '1hr',
        },
        {
            id: 2,
            image: '<span class="grid place-content-center w-9 h-9 rounded-full bg-info-light dark:bg-info text-info dark:text-info-light"><svg xmlns="http://www.w3.org/2000/svg" class="w-5 h-5" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"><circle cx="12" cy="12" r="10"></circle><line x1="12" y1="16" x2="12" y2="12"></line><line x1="12" y1="8" x2="12.01" y2="8"></line></svg></span>',
            title: 'Did you know?',
            message: 'You can switch between artboards.',
            time: '2hr',
        },
        {
            id: 3,
            image: '<span class="grid place-content-center w-9 h-9 rounded-full bg-danger-light dark:bg-danger text-danger dark:text-danger-light"> <svg xmlns="http://www.w3.org/2000/svg" class="w-5 h-5" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg></span>',
            title: 'Something went wrong!',
            message: 'Send Reposrt',
            time: '2days',
        },
        {
            id: 4,
            image: '<span class="grid place-content-center w-9 h-9 rounded-full bg-warning-light dark:bg-warning text-warning dark:text-warning-light"><svg xmlns="http://www.w3.org/2000/svg" class="w-5 h-5" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round">    <circle cx="12" cy="12" r="10"></circle>    <line x1="12" y1="8" x2="12" y2="12"></line>    <line x1="12" y1="16" x2="12.01" y2="16"></line></svg></span>',
            title: 'Warning',
            message: 'Your password strength is low.',
            time: '5days',
        },
    ]);

    const handleLoginClick = async () => {
        navigateTo('/auth/cover-login');
    };

    const handleDashboardClick = async () => {
        console.log(authStore,'authStore')
        const isTrial =  authStore.is_trial_mode;
        if (isTrial) {
            navigateTo('/trial-mode');
        } else {
            navigateTo('/admin');
        }
    };

    onMounted(() => {
        if(authStore.partnerName){
            // console.log(authStore.partnerName,'aaa')
        }


        setActiveDropdown();
    });

    watch(route, (to, from) => {
        setActiveDropdown();
    });

    const setActiveDropdown = () => {
        const selector = document.querySelector('ul.horizontal-menu a[href="' + window.location.pathname + '"]');
        if (selector) {
            selector.classList.add('active');
            const all: any = document.querySelectorAll('ul.horizontal-menu .nav-link.active');
            for (let i = 0; i < all.length; i++) {
                all[0]?.classList.remove('active');
            }
            const ul: any = selector.closest('ul.sub-menu');
            if (ul) {
                let ele: any = ul.closest('li.menu').querySelectorAll('.nav-link');
                if (ele) {
                    ele = ele[0];
                    setTimeout(() => {
                        ele?.classList.add('active');
                    });
                }
            }
        }
    };

    const removeNotification = (value: number) => {
        notifications.value = notifications.value.filter((d) => d.id !== value);
    };

    const removeMessage = (value: number) => {
        messages.value = messages.value.filter((d) => d.id !== value);
    };
</script>
